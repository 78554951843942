
import { defineComponent, inject } from "vue";
import { Question, store, storeKey } from "@/services/Store";
import { DefaultBasicUtil, IBasicUtil } from "../interfaces/IBasicUtil";
import shiken from "@/services/shiken";

export default defineComponent({
  name: "Result",
  components: {},
  data() {
    return {
      shikenId: 1 as number
    }
  },
  setup() {
    const { data, restTime, isError } = inject(
      storeKey
    ) as store;
    return {
      basicbasicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
      data,
      restTime,
      isError,
    };
  },
  created() {
    const isAuth = this.basicbasicUtil.isAuth("1");
    if (!isAuth) this.$router.push("/auth");
    if (this.data.length == 0) {
      return location.href = "/"
    }
    this.shikenId = this.data[0][0].shikenId
  },

  methods: {
    displayStr(q: Question, val: any){
      const arr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"]
      if (q.answerType == 1) {
        const index = Number(val) - 1
        if (index < 0) return ""
        return arr[index]
      }
      else if (q.answerType == 2) {
        let list = String(val).split("|")
        let ret: string[] = []
        list.forEach(l => {
          const index = Number(l) - 1
          if (index < 0) return
          ret.push(arr[index])
        })
        return ret.join(", ")
      }
      if (q.answerType == 4) {
        if (val == undefined || val == "") return ""
        return val
      }

      return String(val)
    },

    displayAnswer(q: Question, val: any, answer: any){
      if (q.answerType == 4) {
        return Number(val).toLocaleString() == Number(answer).toLocaleString()
      }

      return String(val) == String(answer)
    }
  },
});
