
export default [
    {
        "id": 1,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>奢侈：倹約<br><br>原因：（　　）",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "要因",
        "choice2": "分析",
        "choice3": "結果",
        "choice4": "背景",
        "choice5": "影響",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 2,
        "shikenId": 1,
        "parentNo": 2,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>秀抜：出色<br><br>堅牢：（　　）",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "優美",
        "choice2": "頑迷",
        "choice3": "脆弱",
        "choice4": "堅持",
        "choice5": "頑強",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 3,
        "shikenId": 1,
        "parentNo": 3,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>ケチャップ：マヨネーズ<br><br>みかん：（　　）",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "ぶどう",
        "choice2": "収穫",
        "choice3": "農家",
        "choice4": "ジュース",
        "choice5": "果実",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 4,
        "shikenId": 1,
        "parentNo": 4,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>金属：銀<br><br>恒星：（　　）",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "惑星",
        "choice2": "土星",
        "choice3": "月",
        "choice4": "太陽",
        "choice5": "彗星",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 5,
        "shikenId": 1,
        "parentNo": 5,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>段ボール：梱包<br><br>ワイヤー：（　　）",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "牽引",
        "choice2": "切断",
        "choice3": "ケーブル",
        "choice4": "荷物",
        "choice5": "荷重",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 6,
        "shikenId": 1,
        "parentNo": 6,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>グローブ：ボール<br><br>グラス：（　　）",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "飲食",
        "choice2": "コースター",
        "choice3": "レストラン",
        "choice4": "洗浄",
        "choice5": "食器",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 7,
        "shikenId": 1,
        "parentNo": 7,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>スパイス：ナツメグ<br><br>家具：（　　）",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "家屋",
        "choice2": "ベランダ",
        "choice3": "ソファ",
        "choice4": "寝室",
        "choice5": "物置",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 8,
        "shikenId": 1,
        "parentNo": 8,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>行楽：探勝<br><br>振舞い：（　　）",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "配慮",
        "choice2": "容姿",
        "choice3": "肢体",
        "choice4": "思慮",
        "choice5": "挙止",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 9,
        "shikenId": 1,
        "parentNo": 9,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>鍋：調理<br><br>マフラー：（　　）",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "ストール",
        "choice2": "冬",
        "choice3": "毛糸",
        "choice4": "繊維",
        "choice5": "防寒",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 10,
        "shikenId": 1,
        "parentNo": 10,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係の対になるよう（　　）に当てはまるものをＡ～Ｅの中から１つ選びなさい。<br><br>乱雑：整頓<br><br>簡潔：（　　）",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "端的",
        "choice2": "明解",
        "choice3": "冗長",
        "choice4": "些末",
        "choice5": "中庸",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 11,
        "shikenId": 1,
        "parentNo": 11,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>プラスチック：石油<br><br>ア　衣服：シャツ<br>イ　バター：乳製品<br>ウ　生糸：繭",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 12,
        "shikenId": 1,
        "parentNo": 12,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>剣呑：不穏<br><br>ア　当座：姑息<br>イ　旧弊：因習<br>ウ　多作：寡作",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 13,
        "shikenId": 1,
        "parentNo": 13,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>ボルト：ナット<br><br>ア　ペン：紙<br>イ　ほうき：ちりとり<br>ウ　コーヒー：紅茶",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 14,
        "shikenId": 1,
        "parentNo": 14,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>野球：テニス<br><br>ア　スキー：ゴーグル<br>イ　ピアノ：フルート<br>ウ　バット：グローブ",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 15,
        "shikenId": 1,
        "parentNo": 15,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>ミシン：裁縫<br><br>ア　貨物船：輸送<br>イ　トラック：運転手<br>ウ　電卓：計算",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 16,
        "shikenId": 1,
        "parentNo": 16,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>明媚：美麗<br><br>ア　厚情：思いやり<br>イ　心理：斟酌<br>ウ　連続：離散",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 17,
        "shikenId": 1,
        "parentNo": 17,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>望遠鏡：レンズ<br><br>ア　樹木：枝<br>イ　海：山<br>ウ　家：玄関",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 18,
        "shikenId": 1,
        "parentNo": 18,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "最初に提示された二語の関係を考え、同じ関係のものをＡ～Ｆの中から１つ選びなさい。<br><br>狭窄：広漠<br><br>ア　荘重：軽快<br>イ　危険：安全<br>ウ　惻隠：憐憫",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 19,
        "shikenId": 1,
        "parentNo": 19,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>相手の自由な言動を抑制すること</u>",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "疎外",
        "choice2": "牽制",
        "choice3": "挑発",
        "choice4": "忖度",
        "choice5": "慰撫",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 20,
        "shikenId": 1,
        "parentNo": 20,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>思いやりがなく、そっけない</u>こと",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "そつがない",
        "choice2": "つつがない",
        "choice3": "よるべない",
        "choice4": "すげない",
        "choice5": "やるせない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 21,
        "shikenId": 1,
        "parentNo": 21,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>弁舌がよどみなく流暢なさま</u>",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "立て板に水",
        "choice2": "口八丁手八丁",
        "choice3": "歯に衣を着せない",
        "choice4": "舌先三寸",
        "choice5": "口さがない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 22,
        "shikenId": 1,
        "parentNo": 22,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>動作がゆっくりと</u>しているさま",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "すこぶる",
        "choice2": "つとに",
        "choice3": "やおら",
        "choice4": "いきおい",
        "choice5": "いみじくも",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 23,
        "shikenId": 1,
        "parentNo": 23,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>好き嫌いなく、食欲が旺盛なこと</u>",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "貪婪",
        "choice2": "過食",
        "choice3": "壮健",
        "choice4": "頑健",
        "choice5": "健啖",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 24,
        "shikenId": 1,
        "parentNo": 24,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>意味をおし広げて述べること</u>",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "推敲",
        "choice2": "帰納",
        "choice3": "誰何",
        "choice4": "敷衍",
        "choice5": "述懐",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 25,
        "shikenId": 1,
        "parentNo": 25,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>つつしんだ態度で従うこと</u>",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "恭順",
        "choice2": "盲従",
        "choice3": "面従",
        "choice4": "賛助",
        "choice5": "恭悦",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 26,
        "shikenId": 1,
        "parentNo": 26,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>人の値打ちや体面のこと</u>",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "恬淡",
        "choice2": "沽券",
        "choice3": "閨秀",
        "choice4": "私淑",
        "choice5": "慧眼",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 27,
        "shikenId": 1,
        "parentNo": 27,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>態度が丁寧で礼儀正しいこと</u>",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "聡明",
        "choice2": "秀麗",
        "choice3": "慇懃",
        "choice4": "傲岸",
        "choice5": "気丈",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 28,
        "shikenId": 1,
        "parentNo": 28,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br>いかにももっともなことだと思って<u>承知する</u>",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "わきまえる",
        "choice2": "たしなめる",
        "choice3": "あげつらう",
        "choice4": "へつらう",
        "choice5": "うべなう",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 29,
        "shikenId": 1,
        "parentNo": 29,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>物語などのあらすじを短くまとめたもの</u>",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "抜粋",
        "choice2": "梗概",
        "choice3": "揮毫",
        "choice4": "佳作",
        "choice5": "大意",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 30,
        "shikenId": 1,
        "parentNo": 30,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>祝いの言葉を述べる</u>",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "そそのかす",
        "choice2": "ことほぐ",
        "choice3": "おもねる",
        "choice4": "へりくだる",
        "choice5": "ほめそやす",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 31,
        "shikenId": 1,
        "parentNo": 31,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>冷淡な態度であしらうこと</u>",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "冷遇",
        "choice2": "拒絶",
        "choice3": "接待",
        "choice4": "従順",
        "choice5": "罵倒",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 32,
        "shikenId": 1,
        "parentNo": 32,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>手に負えなくて困ること</u>",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "倦怠",
        "choice2": "飽和",
        "choice3": "困憊",
        "choice4": "閉口",
        "choice5": "脱力",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 33,
        "shikenId": 1,
        "parentNo": 33,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>たしなみがなく、不作法である</u>こと",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "とめどない",
        "choice2": "如才ない",
        "choice3": "はしたない",
        "choice4": "抜かりない",
        "choice5": "ふがいない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 34,
        "shikenId": 1,
        "parentNo": 34,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>めったに世に出ないほど優れていること</u>",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "不世出",
        "choice2": "怪気炎",
        "choice3": "不退転",
        "choice4": "有頂天",
        "choice5": "金輪際",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 35,
        "shikenId": 1,
        "parentNo": 35,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>さっぱりとしてあか抜けていること</u>",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "飄逸",
        "choice2": "洒脱",
        "choice3": "優雅",
        "choice4": "鷹揚",
        "choice5": "尊大",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 36,
        "shikenId": 1,
        "parentNo": 36,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "下線部のことばと意味が最も合致するものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u>ちらりと見ること</u>",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "一過",
        "choice2": "一抹",
        "choice3": "一献",
        "choice4": "一縷",
        "choice5": "一瞥",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 37,
        "shikenId": 1,
        "parentNo": 37,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>先生<u><b>に</b></u>進学を勧められる",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "海外<u><b>に</b></u>留学する",
        "choice2": "グループのリーダー<u><b>に</b></u>なる",
        "choice3": "明日の13時<u><b>に</b></u>納品する",
        "choice4": "一足飛び<u><b>に</b></u>昇進する",
        "choice5": "誰か<u><b>に</b></u>後をつけられる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 38,
        "shikenId": 1,
        "parentNo": 38,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>人<u><b>の</b></u>嫌がることを進んで行う",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "家<u><b>の</b></u>裏庭に畑を作る",
        "choice2": "来週<u><b>の</b></u>予定を確認する",
        "choice3": "妹<u><b>の</b></u>友だちが遊びに来た",
        "choice4": "車<u><b>の</b></u>通った後が轍になる",
        "choice5": "おととい<u><b>の</b></u>夜から胃が痛い",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 39,
        "shikenId": 1,
        "parentNo": 39,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>困っている人に親切心<u><b>から</b></u>声をかける",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "５時<u><b>から</b></u>６時の間に到着する",
        "choice2": "海外<u><b>から</b></u>帰国する",
        "choice3": "寝不足<u><b>から</b></u>注意が散漫になる",
        "choice4": "母<u><b>から</b></u>小言を言われる",
        "choice5": "コップ<u><b>から</b></u>水がこぼれる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 40,
        "shikenId": 1,
        "parentNo": 40,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>３勝１敗<u><b>で</b></u>勝ち越す",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "駅<u><b>で</b></u>待ち合わせをする",
        "choice2": "急病<u><b>で</b></u>会社を欠勤する",
        "choice3": "全会一致<u><b>で</b></u>可決される",
        "choice4": "５人１組<u><b>で</b></u>グループを作る",
        "choice5": "あと２時間<u><b>で</b></u>仕上がる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 41,
        "shikenId": 1,
        "parentNo": 41,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>彼は１週間ほど帰省する<u><b>そうだ</b></u>",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "海外ではとても人気がある<u><b>そうだ</b></u>",
        "choice2": "夜には雪になり<u><b>そうだ</b></u>",
        "choice3": "表情がとても苦し<u><b>そうだ</b></u>",
        "choice4": "祖父は１週間前より元気<u><b>そうだ</b></u>",
        "choice5": "このプロジェクトは大仕事になり<u><b>そうだ</b></u>",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 42,
        "shikenId": 1,
        "parentNo": 42,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>たくさんの貨物が積載さ<u><b>れる</b></u>",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "続編の刊行が待望さ<u><b>れる</b></u>",
        "choice2": "おいしくていくらでも食べら<u><b>れる</b></u>",
        "choice3": "社長が退院さ<u><b>れる</b></u>",
        "choice4": "新作映画が公開さ<u><b>れる</b></u>",
        "choice5": "乗客の安否が憂慮さ<u><b>れる</b></u>",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 43,
        "shikenId": 1,
        "parentNo": 43,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>明日の遠足は中止<u><b>と</b></u>なった",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "勝敗は大方の予想<u><b>と</b></u>異なっていた",
        "choice2": "代表者は彼を含む３人<u><b>と</b></u>なった",
        "choice3": "彼の病状は快方に向かう<u><b>と</b></u>思われた",
        "choice4": "日曜日に友人<u><b>と</b></u>買い物に出かけた",
        "choice5": "今年中の完成は難しい<u><b>と</b></u>見られる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 44,
        "shikenId": 1,
        "parentNo": 44,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>すぐ済ませようと思い<u><b>つつ</b></u>後回しにしてしまう",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "洗濯物をたたみ<u><b>つつ</b></u>、子どもの様子を見る",
        "choice2": "社会復帰したいと思い<u><b>つつ</b></u>、二の足を踏む",
        "choice3": "政策の効果が現れ<u><b>つつ</b></u>ある",
        "choice4": "予算を勘案し<u><b>つつ</b></u>工程を決める",
        "choice5": "物価が上昇し<u><b>つつ</b></u>ある",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 45,
        "shikenId": 1,
        "parentNo": 45,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>あの車は彼のお兄さん<u><b>の</b></u>だ",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "父<u><b>の</b></u>運転で旅行に出かける",
        "choice2": "先生<u><b>の</b></u>言ったことを思い出す",
        "choice3": "休日に散歩する<u><b>の</b></u>を習慣にする",
        "choice4": "大学<u><b>の</b></u>講堂で待ち合わせをする",
        "choice5": "テスト<u><b>の</b></u>結果が戻ってくる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 46,
        "shikenId": 1,
        "parentNo": 46,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>リンゴ<u><b>より</b></u>桃のほうが好きだ",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "写真で見る<u><b>より</b></u>実際のほうが若く見える",
        "choice2": "その件は山田<u><b>より</b></u>説明します",
        "choice3": "この線<u><b>より</b></u>内側には入ってはいけない",
        "choice4": "これ<u><b>より</b></u>試験の説明を始めます",
        "choice5": "遠方<u><b>より</b></u>はるばる訪れる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 47,
        "shikenId": 1,
        "parentNo": 47,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>料理<u><b>が</b></u>できるようになったのは最近だ",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "彼<u><b>が</b></u>予想したとおりの結果になった",
        "choice2": "僕<u><b>が</b></u>できることなら何でもします",
        "choice3": "来場者の誘導は彼<u><b>が</b></u>担当する",
        "choice4": "２人の子ども<u><b>が</b></u>仲よく遊んでいる",
        "choice5": "暑いのでビール<u><b>が</b></u>飲みたくなる",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 48,
        "shikenId": 1,
        "parentNo": 48,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>本当に大変なのは合格して<u><b>から</b></u>だ",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "明日<u><b>から</b></u>３連休なので楽しみだ",
        "choice2": "窓<u><b>から</b></u>光が差し込む",
        "choice3": "作業を終えた人<u><b>から</b></u>順次帰途につく",
        "choice4": "引き受けた<u><b>から</b></u>には最後までやる",
        "choice5": "ウイルスが人<u><b>から</b></u>人へ感染する",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 49,
        "shikenId": 1,
        "parentNo": 49,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>庭先に水を<u><b>打つ</b></u>",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "後頭部を強く<u><b>打つ</b></u>",
        "choice2": "地盤を固めるために杭を<u><b>打つ</b></u>",
        "choice3": "夜中に寝返りを<u><b>打つ</b></u>",
        "choice4": "古時計が午前０時を<u><b>打つ</b></u>",
        "choice5": "魚を獲るために投網を<u><b>打つ</b></u>",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 50,
        "shikenId": 1,
        "parentNo": 50,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>暗黙の<u><b>うち</b></u>に理解し合う",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "腹の<u><b>うち</b></u>を探り合う",
        "choice2": "<u><b>うち</b></u>に秘めた思いを詩歌に表現する",
        "choice3": "計画は成功の<u><b>うち</b></u>に終わった",
        "choice4": "複数の<u><b>うち</b></u>から一つを選ぶ",
        "choice5": "辛さの<u><b>うち</b></u>にも奥深い味わいがある",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 51,
        "shikenId": 1,
        "parentNo": 51,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br><u><b>気</b></u>を悪くしたなら謝りたい",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "サイダーの<u><b>気</b></u>が抜ける",
        "choice2": "教室中に陰鬱な<u><b>気</b></u>が漂う",
        "choice3": "彼がどうする<u><b>気</b></u>なのかわからない",
        "choice4": "<u><b>気</b></u>があるそぶりを見せる",
        "choice5": "驚きのあまり<u><b>気</b></u>を失う",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 52,
        "shikenId": 1,
        "parentNo": 52,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>過去にひどい<u><b>目</b></u>に遭ったことがある",
        "question2": "",
        "answer": "1",
        "score": 1,
        "choice1": "今回の取引ではいい<u><b>目</b></u>を見た",
        "choice2": "サイコロの<u><b>目</b></u>が大きいほうを勝ちとする",
        "choice3": "靴ひもの結び<u><b>目</b></u>をほどく",
        "choice4": "<u><b>目</b></u>の細かいふるいでより分ける",
        "choice5": "彼は見た<u><b>目</b></u>がよくない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 53,
        "shikenId": 1,
        "parentNo": 53,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>威勢よく啖呵を<u><b>切る</b></u>",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "支払いのために小切手を<u><b>切る</b></u>",
        "choice2": "歌舞伎役者が大見得を<u><b>切る</b></u>",
        "choice3": "急にハンドルを左に<u><b>切る</b></u>",
        "choice4": "届いた手紙の封を<u><b>切る</b></u>",
        "choice5": "用事が済んだので電話を<u><b>切る</b></u>",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 54,
        "shikenId": 1,
        "parentNo": 54,
        "no": 1,
        "answerType": 1,
        "time": 15,
        "question1": "各問いについて、下線部の語が最も近い意味で使われているものを、Ａ～Ｅの中から１つ選びなさい。<br><br>不躾な振舞いで不興を<u><b>買う</b></u>",
        "question2": "",
        "answer": "3",
        "score": 1,
        "choice1": "土地の権利を<u><b>買う</b></u>",
        "choice2": "売られた喧嘩を<u><b>買う</b></u>",
        "choice3": "恨みを<u><b>買う</b></u>ような行動を慎む",
        "choice4": "彼なりの努力は<u><b>買う</b></u>つもりだ",
        "choice5": "プロジェクトの成功に一役<u><b>買う</b></u>",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 55,
        "shikenId": 1,
        "parentNo": 55,
        "no": 1,
        "answerType": 3,
        "time": 180,
        "question1": "次の文を読んで、各問いに答えなさい。<br><br>　ノックスの十戒は、自身も推理作家であるロナルド・ノックスが1928年の著作で言及した、推理小説が備えるべき10のルールである。その第五戒は「主要人物として中国人を登場させてはならない」と定めている。十戒を定めたノックスの意図は「推理小説が知的遊戯であるためには、そこで作家と読者のフェアプレイができなければならない」という前提に貫かれており、この前提は透徹した論理性に裏打ちされている。では、その論理性のためになぜ、中国人の登場人物を除かなければならないのか。<br>　サックス・ローマーの通俗小説に登場する架空の中国人フー・マンチュー博士の存在は、少なくともこの問いを検討するための補助線になるだろう。切れ長の目にドジョウひげという風体の彼は中国人による世界征服を野望とし、神秘の力をも動員して欧米社会の秩序を存分に脅かした。彼の存在を踏まえると、第五戒の意図を汲むことができるように思う。例えば、殺人が行われた密室から不可思議な力で脱出できるような人物がいてはフェアプレイが損なわれてしまう。<br>　当時の欧米において中国人・東洋人に対する大いに偏った見方があったことに苦言を呈すべきではあろう。ただ、ノックスによる第五戒は、フェアプレイ精神を重んじる推理小説が、［　　　］に浴した存在を組み込むべきでない、という戒めであると考えられる。",
        "question2": "文中の空欄に入る語句を、文中から３文字以内で抜き出しなさい。",
        "answer": "神秘",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 56,
        "shikenId": 1,
        "parentNo": 55,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "次の文を読んで、各問いに答えなさい。<br><br>　ノックスの十戒は、自身も推理作家であるロナルド・ノックスが1928年の著作で言及した、推理小説が備えるべき10のルールである。その第五戒は「主要人物として中国人を登場させてはならない」と定めている。十戒を定めたノックスの意図は「推理小説が知的遊戯であるためには、そこで作家と読者のフェアプレイができなければならない」という前提に貫かれており、この前提は透徹した論理性に裏打ちされている。では、その論理性のためになぜ、中国人の登場人物を除かなければならないのか。<br>　サックス・ローマーの通俗小説に登場する架空の中国人フー・マンチュー博士の存在は、少なくともこの問いを検討するための補助線になるだろう。切れ長の目にドジョウひげという風体の彼は中国人による世界征服を野望とし、神秘の力をも動員して欧米社会の秩序を存分に脅かした。彼の存在を踏まえると、第五戒の意図を汲むことができるように思う。例えば、殺人が行われた密室から不可思議な力で脱出できるような人物がいてはフェアプレイが損なわれてしまう。<br>　当時の欧米において中国人・東洋人に対する大いに偏った見方があったことに苦言を呈すべきではあろう。ただ、ノックスによる第五戒は、フェアプレイ精神を重んじる推理小説が、［　　　］に浴した存在を組み込むべきでない、という戒めであると考えられる。",
        "question2": "ノックスの十戒について、文中で述べられていることと合致するものは、次のうちどれか。",
        "answer": "3",
        "score": 1,
        "choice1": "ノックスの十戒は、推理作家ロナルド・ノックスが自身の作品で犯した失敗に対する戒めとして提唱された。",
        "choice2": "ノックスの十戒は、推理小説をはじめとした小説作品があまねく服するべきルールとして設定されている。",
        "choice3": "ノックスの十戒は、推理小説が知的な遊戯であるために守るべきルールとして考案された。",
        "choice4": "推理小説を通じて知的な楽しみを得るためには、ノックスの十戒のようなルールが不可欠である。",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 57,
        "shikenId": 1,
        "parentNo": 55,
        "no": 3,
        "answerType": 2,
        "time": 0,
        "question1": "次の文を読んで、各問いに答えなさい。<br><br>　ノックスの十戒は、自身も推理作家であるロナルド・ノックスが1928年の著作で言及した、推理小説が備えるべき10のルールである。その第五戒は「主要人物として中国人を登場させてはならない」と定めている。十戒を定めたノックスの意図は「推理小説が知的遊戯であるためには、そこで作家と読者のフェアプレイができなければならない」という前提に貫かれており、この前提は透徹した論理性に裏打ちされている。では、その論理性のためになぜ、中国人の登場人物を除かなければならないのか。<br>　サックス・ローマーの通俗小説に登場する架空の中国人フー・マンチュー博士の存在は、少なくともこの問いを検討するための補助線になるだろう。切れ長の目にドジョウひげという風体の彼は中国人による世界征服を野望とし、神秘の力をも動員して欧米社会の秩序を存分に脅かした。彼の存在を踏まえると、第五戒の意図を汲むことができるように思う。例えば、殺人が行われた密室から不可思議な力で脱出できるような人物がいてはフェアプレイが損なわれてしまう。<br>　当時の欧米において中国人・東洋人に対する大いに偏った見方があったことに苦言を呈すべきではあろう。ただ、ノックスによる第五戒は、フェアプレイ精神を重んじる推理小説が、［　　　］に浴した存在を組み込むべきでない、という戒めであると考えられる。",
        "question2": "文中で述べられていることと合致するものは、次のうちどれか。当てはまるものをすべて選びなさい。",
        "answer": "1|4",
        "score": 1,
        "choice1": "推理作家ロナルド・ノックスは、自身の著作でノックスの十戒に言及した。",
        "choice2": "フー・マンチュー博士という登場人物は、ノックスの第五戒が定められた意義を示すために考案された。",
        "choice3": "殺人が行われた密室から脱出することができるのは、不可思議な力を持った中国人だけである。",
        "choice4": "ノックスの第五戒の存在は、当時の欧米社会において中国人・東洋人に対する偏った見方があったことをうかがわせる。",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 58,
        "shikenId": 1,
        "parentNo": 56,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "Ｐ、Ｑ、Ｒ、Ｓ、Ｔの５人が徒競走を行い、ゴールした順位について次のことが分かっている。ただし、同着の者はいなかったものとする。<br><br>Ⅰ　最下位はＰではなかった<br>Ⅱ　ＳとＴは連続してゴールした<br>Ⅲ　Ｔの順位はＲより２つ上だった",
        "question2": "Ｑの順位としてあり得ないものはどれか。Ａ～Ｆの中から１つ選びなさい。",
        "answer": "3",
        "score": 1,
        "choice1": "１位",
        "choice2": "２位",
        "choice3": "３位",
        "choice4": "４位",
        "choice5": "５位",
        "choice6": "すべての順位があり得る",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 59,
        "shikenId": 1,
        "parentNo": 56,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "Ｐ、Ｑ、Ｒ、Ｓ、Ｔの５人が徒競走を行い、ゴールした順位について次のことが分かっている。ただし、同着の者はいなかったものとする。<br><br>Ⅰ　最下位はＰではなかった<br>Ⅱ　ＳとＴは連続してゴールした<br>Ⅲ　Ｔの順位はＲより２つ上だった",
        "question2": "次の推論ア、イ、ウのうち、必ずしも誤りとは言えないものはどれか。Ａ～Ｈの中から１つ選びなさい。<br><br>ア　ＱとＳは連続してゴールした<br>イ　ＱとＴの間に３人がゴールした<br>ウ　ＰとＳの間に３人がゴールした",
        "answer": "4",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイ",
        "choice5": "アとウ",
        "choice6": "イとウ",
        "choice7": "アとイとウ",
        "choice8": "いずれも誤り",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 60,
        "shikenId": 1,
        "parentNo": 56,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "Ｐ、Ｑ、Ｒ、Ｓ、Ｔの５人が徒競走を行い、ゴールした順位について次のことが分かっている。ただし、同着の者はいなかったものとする。<br><br>Ⅰ　最下位はＰではなかった<br>Ⅱ　ＳとＴは連続してゴールした<br>Ⅲ　Ｔの順位はＲより２つ上だった",
        "question2": "最も少ない情報でＰ、Ｑ、Ｒ、Ｓ、Ｔの順位がすべて分かるためには、ⅠからⅢの情報のほかに、次のカ、キ、クのうちどれが加わればよいか。Ａ～Ｈの中から１つ選びなさい。<br><br>カ　Ｒより後に２人以上がゴールした<br>キ　Ｑは最下位だった<br>ク　Ｓは２位だった",
        "answer": "1",
        "score": 1,
        "choice1": "カだけ",
        "choice2": "キだけ",
        "choice3": "クだけ",
        "choice4": "カとキの両方",
        "choice5": "カとクの両方",
        "choice6": "キとクの両方",
        "choice7": "カとキとクのすべて",
        "choice8": "カ、キ、クのすべてが加わっても分からない",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 61,
        "shikenId": 1,
        "parentNo": 57,
        "no": 1,
        "answerType": 1,
        "time": 120,
        "question1": "Ｐ、Ｑ、Ｒ、Ｓ、Ｔの５人が100点満点の学力テストを受けた。その結果について、次のことが分かっている。<br><br>Ⅰ　Ｐ、Ｑの平均点は75点である<br>Ⅱ　Ｐ、Ｑ、Ｒの平均点は60点である<br>Ⅲ　Ｐ、Ｑ、Ｒ、Ｓ、Ｔの平均点は50点である",
        "question2": "次の推論ア、イ、ウのうち、必ずしも誤りとは言えないものはどれか。Ａ～Ｈの中から１つ選びなさい。<br><br>ア　100点満点を取った人物が２人いる<br>イ　Ｑ、Ｔの平均点は80点以上である<br>ウ　５人のうち最高得点を取った人物はＳである",
        "answer": "2",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイの両方",
        "choice5": "アとウの両方",
        "choice6": "イとウの両方",
        "choice7": "アとイとウのすべて",
        "choice8": "ア、イ、ウのいずれも誤り",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 62,
        "shikenId": 1,
        "parentNo": 57,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "Ｐ、Ｑ、Ｒ、Ｓ、Ｔの５人が100点満点の学力テストを受けた。その結果について、次のことが分かっている。<br><br>Ⅰ　Ｐ、Ｑの平均点は75点である<br>Ⅱ　Ｐ、Ｑ、Ｒの平均点は60点である<br>Ⅲ　Ｐ、Ｑ、Ｒ、Ｓ、Ｔの平均点は50点である",
        "question2": "次の推論カ、キの正誤を考え、Ａ～Ｉの中から正しいものを１つ選びなさい。<br><br>カ　５人のうち最低得点を取った人物がＲであれば、ＱとＳの得点差は10点未満である<br>キ　５人のうち最低得点を取った人物がＴであれば、Ｒ、Ｓの平均点は40点以上である",
        "answer": "8",
        "score": 1,
        "choice1": "カもキも正しい",
        "choice2": "カは正しいが、キはどちらとも言えない",
        "choice3": "カは正しいが、キは誤り",
        "choice4": "カはどちらとも言えないが、キは正しい",
        "choice5": "カもキもどちらとも言えない",
        "choice6": "カはどちらとも言えないが、キは誤り",
        "choice7": "カは誤りだが、キは正しい",
        "choice8": "カは誤りだが、キはどちらとも言えない",
        "choice9": "カもキも誤り",
        "choice10": ""
    },
    {
        "id": 63,
        "shikenId": 1,
        "parentNo": 58,
        "no": 1,
        "answerType": 1,
        "time": 120,
        "question1": "ある自然数について、次のような３通りの情報がある。<br><br>Ｐ　この数は６の倍数である<br>Ｑ　この数は偶数である<br>Ｒ　この数は３と４の公倍数である<br><br>以上の情報は、必ずしもすべてが信頼できるとは限らない。そこで、さまざまな場合を想定して推論がなされた。",
        "question2": "次の推論ア、イ、ウのうち、正しいものはどれか。Ａ～Ｈの中から１つ選びなさい。<br><br>ア　Ｐが正しければＱも必ず正しい<br>イ　Ｑが正しければＲも必ず正しい<br>ウ　Ｒが正しければＰも必ず正しい",
        "answer": "5",
        "score": 1,
        "choice1": "アだけ",
        "choice2": "イだけ",
        "choice3": "ウだけ",
        "choice4": "アとイの両方",
        "choice5": "アとウの両方",
        "choice6": "イとウの両方",
        "choice7": "アとイとウのすべて",
        "choice8": "正しい推論はない",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 64,
        "shikenId": 1,
        "parentNo": 58,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "ある自然数について、次のような３通りの情報がある。<br><br>Ｐ　この数は６の倍数である<br>Ｑ　この数は偶数である<br>Ｒ　この数は３と４の公倍数である<br><br>以上の情報は、必ずしもすべてが信頼できるとは限らない。そこで、さまざまな場合を想定して推論がなされた。",
        "question2": "次の推論カ、キ、クのうち、正しいものはどれか。Ａ～Ｈの中から１つ選びなさい。<br><br>カ　Ｐが正しければＲも必ず正しい<br>キ　Ｑが正しければＰも必ず正しい<br>ク　Ｒが正しければＱも必ず正しい",
        "answer": "3",
        "score": 1,
        "choice1": "カだけ",
        "choice2": "キだけ",
        "choice3": "クだけ",
        "choice4": "カとキの両方",
        "choice5": "カとクの両方",
        "choice6": "キとクの両方",
        "choice7": "カとキとクのすべて",
        "choice8": "正しい推論はない",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 65,
        "shikenId": 1,
        "parentNo": 59,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "ある高校では夏期講習と冬期講習を実施しており、生徒は夏期と冬期にそれぞれ１科目以上を履修することとされている。下表は、その高校の１学年を対象に、夏期講習と冬期講習で履修した科目数を調査したものである。<br><br><img src='/img/1_1_65_1.png' class='q-img'/>",
        "question2": "冬期講習より夏期講習のほうが履修した科目数が多い生徒の人数は、夏期講習より冬期講習のほうが履修した科目数が多い生徒の人数の何倍か（必要があれば、最後に小数第２位を四捨五入すること）。",
        "answer": "2",
        "score": 1,
        "choice1": "1.2倍",
        "choice2": "1.5倍",
        "choice3": "1.8倍",
        "choice4": "2.1倍",
        "choice5": "2.4倍",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 66,
        "shikenId": 1,
        "parentNo": 59,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "ある高校では夏期講習と冬期講習を実施しており、生徒は夏期と冬期にそれぞれ１科目以上を履修することとされている。下表は、その高校の１学年を対象に、夏期講習と冬期講習で履修した科目数を調査したものである。<br><br><img src='/img/1_1_65_1.png' class='q-img'/>",
        "question2": "夏期講習と冬期講習で履修した科目数の合計が７科目以上である生徒のうち、夏期講習で３科目を履修した生徒は何％か（必要があれば、最後に小数第１位を四捨五入すること）。",
        "answer": "5",
        "score": 1,
        "choice1": "14%",
        "choice2": "17%",
        "choice3": "20%",
        "choice4": "23%",
        "choice5": "26%",
        "choice6": "29%",
        "choice7": "32%",
        "choice8": "35%",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 67,
        "shikenId": 1,
        "parentNo": 59,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "ある高校では夏期講習と冬期講習を実施しており、生徒は夏期と冬期にそれぞれ１科目以上を履修することとされている。下表は、その高校の１学年を対象に、夏期講習と冬期講習で履修した科目数を調査したものである。<br><br><img src='/img/1_1_65_1.png' class='q-img'/>",
        "question2": "夏期講習で２科目を履修した生徒のうち、冬期講習でも２科目を履修した生徒は20％であった。表中の空欄に入る数値はいくらか。",
        "answer": "5",
        "score": 1,
        "choice1": "10",
        "choice2": "13",
        "choice3": "16",
        "choice4": "19",
        "choice5": "22",
        "choice6": "25",
        "choice7": "28",
        "choice8": "31",
        "choice9": "34",
        "choice10": "37"
    },
    {
        "id": 68,
        "shikenId": 1,
        "parentNo": 60,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "貨物列車Ｘ、Ｙは、貨物の積み込みを行ったうえでＰ駅を出発し、経由するＱ駅、Ｒ駅、Ｓ駅、Ｔ駅において積み下ろしと積み込みを行いながらＵ駅へと向かい、Ｕ駅で残存する貨物をすべて積み下ろす。表１は各駅において貨物列車Ｘ、Ｙが積み込み、積み下ろしを行った貨物の重量と、各駅間の距離をまとめたものである。また、表２は貨物列車Ｘ、Ｙが輸送する貨物の送料をまとめたものである。<br><br>表１<br><img src='/img/1_1_68_1.png' class='q-img'/><br><br>表２<br><img src='/img/1_1_68_2.png' class='q-img'/>",
        "question2": "貨物列車ＸがＵ駅で積み下ろした貨物の重量はいくらか。",
        "answer": "8",
        "score": 1,
        "choice1": "25t",
        "choice2": "31t",
        "choice3": "37t",
        "choice4": "43t",
        "choice5": "49t",
        "choice6": "55t",
        "choice7": "61t",
        "choice8": "67t",
        "choice9": "73t",
        "choice10": "79t"
    },
    {
        "id": 69,
        "shikenId": 1,
        "parentNo": 60,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "貨物列車Ｘ、Ｙは、貨物の積み込みを行ったうえでＰ駅を出発し、経由するＱ駅、Ｒ駅、Ｓ駅、Ｔ駅において積み下ろしと積み込みを行いながらＵ駅へと向かい、Ｕ駅で残存する貨物をすべて積み下ろす。表１は各駅において貨物列車Ｘ、Ｙが積み込み、積み下ろしを行った貨物の重量と、各駅間の距離をまとめたものである。また、表２は貨物列車Ｘ、Ｙが輸送する貨物の送料をまとめたものである。<br><br>表１<br><img src='/img/1_1_68_1.png' class='q-img'/><br><br>表２<br><img src='/img/1_1_68_2.png' class='q-img'/>",
        "question2": "Ｒ駅・Ｓ駅間を走行中において、貨物列車Ｘと貨物列車Ｙが積載している貨物の重量の差はいくらか。",
        "answer": "4",
        "score": 1,
        "choice1": "13t",
        "choice2": "18t",
        "choice3": "23t",
        "choice4": "28t",
        "choice5": "33t",
        "choice6": "38t",
        "choice7": "43t",
        "choice8": "48t",
        "choice9": "53t",
        "choice10": "58t"
    },
    {
        "id": 70,
        "shikenId": 1,
        "parentNo": 60,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "貨物列車Ｘ、Ｙは、貨物の積み込みを行ったうえでＰ駅を出発し、経由するＱ駅、Ｒ駅、Ｓ駅、Ｔ駅において積み下ろしと積み込みを行いながらＵ駅へと向かい、Ｕ駅で残存する貨物をすべて積み下ろす。表１は各駅において貨物列車Ｘ、Ｙが積み込み、積み下ろしを行った貨物の重量と、各駅間の距離をまとめたものである。また、表２は貨物列車Ｘ、Ｙが輸送する貨物の送料をまとめたものである。<br><br>表１<br><img src='/img/1_1_68_1.png' class='q-img'/><br><br>表２<br><img src='/img/1_1_68_2.png' class='q-img'/>",
        "question2": "貨物列車YがS駅で積み下ろした貨物のうち、30tはＰ駅、５tはＱ駅、35tはＲ駅で積み込まれたものであるとき、貨物列車ＹがＳ駅で積み下ろした貨物の送料はいくらか。",
        "answer": "2",
        "score": 1,
        "choice1": "196000円",
        "choice2": "242500円",
        "choice3": "255500円",
        "choice4": "256500円",
        "choice5": "276500円",
        "choice6": "294000円",
        "choice7": "311500円",
        "choice8": "331500円",
        "choice9": "346500円",
        "choice10": "364000円"
    },
    {
        "id": 71,
        "shikenId": 1,
        "parentNo": 61,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "ある小学校の３年生100人に、国語、算数、理科の３科目について得意か得意でないかを尋ねたところ、国語が得意と答えたのは44人、算数が得意と答えたのは35人、理科が得意と答えたのは30人であった。また、国語、算数、理科のいずれの科目も得意でないと答えたのは10人であった。",
        "question2": "算数が得意と答えた人のうち、理科も得意と答えたのは６人であった。理科が得意と答えた人のうち、算数は得意でないと答えたのは何人か。",
        "answer": "8",
        "score": 1,
        "choice1": "10人",
        "choice2": "12人",
        "choice3": "14人",
        "choice4": "16人",
        "choice5": "18人",
        "choice6": "20人",
        "choice7": "22人",
        "choice8": "24人",
        "choice9": "26人",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 72,
        "shikenId": 1,
        "parentNo": 61,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "ある小学校の３年生100人に、国語、算数、理科の３科目について得意か得意でないかを尋ねたところ、国語が得意と答えたのは44人、算数が得意と答えたのは35人、理科が得意と答えたのは30人であった。また、国語、算数、理科のいずれの科目も得意でないと答えたのは10人であった。",
        "question2": "国語が得意と答えた人のうち、理科も得意と答えたのは７人であった。算数のみが得意と答えたのは何人か。",
        "answer": "4",
        "score": 1,
        "choice1": "20人",
        "choice2": "21人",
        "choice3": "22人",
        "choice4": "23人",
        "choice5": "24人",
        "choice6": "25人",
        "choice7": "26人",
        "choice8": "27人",
        "choice9": "28人",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 73,
        "shikenId": 1,
        "parentNo": 61,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "ある小学校の３年生100人に、国語、算数、理科の３科目について得意か得意でないかを尋ねたところ、国語が得意と答えたのは44人、算数が得意と答えたのは35人、理科が得意と答えたのは30人であった。また、国語、算数、理科のいずれの科目も得意でないと答えたのは10人であった。",
        "question2": "国語、算数、理科のうち２科目以上が得意と答えたのが17人であるとき、国語、算数、理科の３科目すべてが得意と答えたのは何人か。",
        "answer": "3",
        "score": 1,
        "choice1": "０人",
        "choice2": "１人",
        "choice3": "２人",
        "choice4": "３人",
        "choice5": "４人",
        "choice6": "５人",
        "choice7": "６人",
        "choice8": "７人",
        "choice9": "８人",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 74,
        "shikenId": 1,
        "parentNo": 62,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "男子生徒と女子生徒が10人ずつ、合計20人おり、この20人の中から委員を選抜する。",
        "question2": "図書委員を男子生徒から４名、女子生徒から２名選ぶとき、選び方は何通りあるか。",
        "answer": "5",
        "score": 1,
        "choice1": "420通り",
        "choice2": "840通り",
        "choice3": "2520通り",
        "choice4": "5040通り",
        "choice5": "9450通り",
        "choice6": "18900通り",
        "choice7": "37800通り",
        "choice8": "75600通り",
        "choice9": "151200通り",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 75,
        "shikenId": 1,
        "parentNo": 62,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "男子生徒と女子生徒が10人ずつ、合計20人おり、この20人の中から委員を選抜する。",
        "question2": "保健委員を３人選ぶとき、少なくとも１人は女子生徒が含まれる選び方は何通りあるか。",
        "answer": "4",
        "score": 1,
        "choice1": "680通り",
        "choice2": "820通り",
        "choice3": "940通り",
        "choice4": "1020通り",
        "choice5": "1200通り",
        "choice6": "1280通り",
        "choice7": "1360通り",
        "choice8": "1440通り",
        "choice9": "1520通り",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 76,
        "shikenId": 1,
        "parentNo": 62,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "男子生徒と女子生徒が10人ずつ、合計20人おり、この20人の中から委員を選抜する。",
        "question2": "文化祭の実行委員として委員長1人、副委員長2人、会計１人を選ぶとき、副委員長が男女１人ずつとなる選び方は何通りあるか。",
        "answer": "6",
        "score": 1,
        "choice1": "1420通り",
        "choice2": "3680通り",
        "choice3": "9290通り",
        "choice4": "15320通り",
        "choice5": "24200通り",
        "choice6": "30600通り",
        "choice7": "48160通り",
        "choice8": "75200通り",
        "choice9": "98900通り",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 77,
        "shikenId": 1,
        "parentNo": 63,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "10から99までの２桁の整数が１つずつ書かれた90枚のカードがある。",
        "question2": "このカードの山から無作為に２枚のカードを選ぶとき、選んだカードに書かれた数の積が奇数になる確率はいくらか。",
        "answer": "1",
        "score": 1,
        "choice1": "22/89",
        "choice2": "29/89",
        "choice3": "33/89",
        "choice4": "101/267",
        "choice5": "104/267",
        "choice6": "108/267",
        "choice7": "325/801",
        "choice8": "334/801",
        "choice9": "343/801",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 78,
        "shikenId": 1,
        "parentNo": 63,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "10から99までの２桁の整数が１つずつ書かれた90枚のカードがある。",
        "question2": "このカードの山から無作為に２枚のカードを選ぶとき、選んだカードに書かれた数の和が奇数になる確率はいくらか。",
        "answer": "4",
        "score": 1,
        "choice1": "22/89",
        "choice2": "29/89",
        "choice3": "33/89",
        "choice4": "45/89",
        "choice5": "131/267",
        "choice6": "56/89",
        "choice7": "170/267",
        "choice8": "59/89",
        "choice9": "184/267",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 79,
        "shikenId": 1,
        "parentNo": 63,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "10から99までの２桁の整数が１つずつ書かれた90枚のカードがある。",
        "question2": "このカードの山から無作為に１枚のカードを選ぶとき、選んだカードに書かれた数の十の位と一の位の差が５以上になる確率はいくらか。",
        "answer": "5",
        "score": 1,
        "choice1": "13/90",
        "choice2": "3/18",
        "choice3": "1/5",
        "choice4": "11/45",
        "choice5": "5/18",
        "choice6": "29/90",
        "choice7": "11/30",
        "choice8": "37/90",
        "choice9": "41/90",
        "choice10": "Ａ～Ｉのいずれでもない"
    },
    {
        "id": 80,
        "shikenId": 2,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "鎖　国",
        "answer": "3",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>主語と述語</u>の関係にある",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 81,
        "shikenId": 2,
        "parentNo": 2,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "豊　富",
        "answer": "1",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>主語と述語</u>の関係にある",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 82,
        "shikenId": 2,
        "parentNo": 3,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "貧　窮",
        "answer": "1",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>主語と述語</u>の関係にある",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 83,
        "shikenId": 2,
        "parentNo": 4,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "新　雪",
        "answer": "4",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>主語と述語</u>の関係にある",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 84,
        "shikenId": 2,
        "parentNo": 5,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "観　劇",
        "answer": "3",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>主語と述語</u>の関係にある",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 85,
        "shikenId": 2,
        "parentNo": 6,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "暖　冬",
        "answer": "2",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "前の漢字が後の漢字を<u>修飾</u>する",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 86,
        "shikenId": 2,
        "parentNo": 7,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "進　退",
        "answer": "4",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "前の漢字が後の漢字を<u>修飾</u>する",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 87,
        "shikenId": 2,
        "parentNo": 8,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "懐　古",
        "answer": "3",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "前の漢字が後の漢字を<u>修飾</u>する",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 88,
        "shikenId": 2,
        "parentNo": 9,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "少　量",
        "answer": "2",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "前の漢字が後の漢字を<u>修飾</u>する",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 89,
        "shikenId": 2,
        "parentNo": 10,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "道　路",
        "answer": "1",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "前の漢字が後の漢字を<u>修飾</u>する",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 90,
        "shikenId": 2,
        "parentNo": 11,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "変　節",
        "answer": "3",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>反対の意味</u>をもつ漢字を重ねる",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 91,
        "shikenId": 2,
        "parentNo": 12,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "調　整",
        "answer": "1",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>反対の意味</u>をもつ漢字を重ねる",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 92,
        "shikenId": 2,
        "parentNo": 13,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "因　果",
        "answer": "2",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>反対の意味</u>をもつ漢字を重ねる",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 93,
        "shikenId": 2,
        "parentNo": 14,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "禁　止",
        "answer": "1",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>反対の意味</u>をもつ漢字を重ねる",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 94,
        "shikenId": 2,
        "parentNo": 15,
        "no": 1,
        "answerType": 1,
        "time": 12,
        "question1": "以下の熟語の成り立ち方としてあてはまるものをＡ～Ｄの中から１つ選びなさい。",
        "question2": "短　期",
        "answer": "4",
        "score": 1,
        "choice1": "<u>似た意味</u>をもつ漢字を重ねる",
        "choice2": "<u>反対の意味</u>をもつ漢字を重ねる",
        "choice3": "<u>動詞の後に目的語</u>をおく",
        "choice4": "Ａ～Ｃのどれにもあてはまらない",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 95,
        "shikenId": 2,
        "parentNo": 16,
        "no": 1,
        "answerType": 1,
        "time": 50,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "ロシア語のアルファベットは33文字から成り、［　　　　］。",
        "answer": "4",
        "score": 1,
        "choice1": "ロシア語を第二言語とする話者数も合わせると世界で４番目に多い",
        "choice2": "その起源については諸説あるとされる",
        "choice3": "18世紀はじめにピョートル大帝によってロシア語の改革が行われた",
        "choice4": "キリル文字と呼ばれる表音文字を用いて表記される",
        "choice5": "「イクラ」や「カチューシャ」などがその例である",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 96,
        "shikenId": 2,
        "parentNo": 16,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "外来語として日本語に取り入れられている語にはロシア語由来のものがあり、［　　　　］。",
        "answer": "5",
        "score": 1,
        "choice1": "ロシア語を第二言語とする話者数も合わせると世界で４番目に多い",
        "choice2": "その起源については諸説あるとされる",
        "choice3": "18世紀はじめにピョートル大帝によってロシア語の改革が行われた",
        "choice4": "キリル文字と呼ばれる表音文字を用いて表記される",
        "choice5": "「イクラ」や「カチューシャ」などがその例である",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 97,
        "shikenId": 2,
        "parentNo": 16,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "ロシア語を母語とする話者数は世界で８番目に多く、［　　　　］。",
        "answer": "1",
        "score": 1,
        "choice1": "ロシア語を第二言語とする話者数も合わせると世界で４番目に多い",
        "choice2": "その起源については諸説あるとされる",
        "choice3": "18世紀はじめにピョートル大帝によってロシア語の改革が行われた",
        "choice4": "キリル文字と呼ばれる表音文字を用いて表記される",
        "choice5": "「イクラ」や「カチューシャ」などがその例である",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 98,
        "shikenId": 2,
        "parentNo": 17,
        "no": 1,
        "answerType": 1,
        "time": 50,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "虫歯が歯そのものの病気であるのに対して、［　　　　］。",
        "answer": "5",
        "score": 1,
        "choice1": "毎日の丁寧なブラッシングと口腔ケアが重要です",
        "choice2": "全身的な疾患を引き起こす原因ともなります",
        "choice3": "40歳以上の日本人の半数が歯周病にかかっているとされます",
        "choice4": "自覚症状を伴わないという特徴があります",
        "choice5": "歯周病は歯の周りにある歯茎や歯を支える骨の病気です",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 99,
        "shikenId": 2,
        "parentNo": 17,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "日本人が歯を失う原因の第１位は歯周病であり、［　　　　］。",
        "answer": "3",
        "score": 1,
        "choice1": "毎日の丁寧なブラッシングと口腔ケアが重要です",
        "choice2": "全身的な疾患を引き起こす原因ともなります",
        "choice3": "40歳以上の日本人の半数が歯周病にかかっているとされます",
        "choice4": "自覚症状を伴わないという特徴があります",
        "choice5": "歯周病は歯の周りにある歯茎や歯を支える骨の病気です",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 100,
        "shikenId": 2,
        "parentNo": 17,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "歯周病菌が毛細血管から搬送されると病変は口腔内にとどまらず、［　　　　］。",
        "answer": "2",
        "score": 1,
        "choice1": "毎日の丁寧なブラッシングと口腔ケアが重要です",
        "choice2": "全身的な疾患を引き起こす原因ともなります",
        "choice3": "40歳以上の日本人の半数が歯周病にかかっているとされます",
        "choice4": "自覚症状を伴わないという特徴があります",
        "choice5": "歯周病は歯の周りにある歯茎や歯を支える骨の病気です",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 101,
        "shikenId": 2,
        "parentNo": 18,
        "no": 1,
        "answerType": 1,
        "time": 50,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "［　　　　］、江戸時代末期にヨーロッパから輸入され始めたとされる。",
        "answer": "4",
        "score": 1,
        "choice1": "ボーラーハットを愛用した著名人は数多く",
        "choice2": "もともと乗馬時に着用する帽子として考案され",
        "choice3": "近年では若者や女性の着用者も見られるようになり",
        "choice4": "ボーラーハットは日本では山高帽とも呼ばれ",
        "choice5": "帽子の山の部分をクラウンといい",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 102,
        "shikenId": 2,
        "parentNo": 18,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "［　　　　］、ボーラーハットは半球状に盛り上がったクラウンが特徴である。",
        "answer": "5",
        "score": 1,
        "choice1": "ボーラーハットを愛用した著名人は数多く",
        "choice2": "もともと乗馬時に着用する帽子として考案され",
        "choice3": "近年では若者や女性の着用者も見られるようになり",
        "choice4": "ボーラーハットは日本では山高帽とも呼ばれ",
        "choice5": "帽子の山の部分をクラウンといい",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 103,
        "shikenId": 2,
        "parentNo": 18,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文を完成させるためにＡ～Ｅの中から最もつながりのよいものを１つ選びなさい。",
        "question2": "［　　　　］、中でもチャーリー・チャップリンが着用したことは広く知られている。",
        "answer": "1",
        "score": 1,
        "choice1": "ボーラーハットを愛用した著名人は数多く",
        "choice2": "もともと乗馬時に着用する帽子として考案され",
        "choice3": "近年では若者や女性の着用者も見られるようになり",
        "choice4": "ボーラーハットは日本では山高帽とも呼ばれ",
        "choice5": "帽子の山の部分をクラウンといい",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 104,
        "shikenId": 2,
        "parentNo": 19,
        "no": 1,
        "answerType": 1,
        "time": 160,
        "question1": "文中の①～④の欄にア～エの語句を入れて文を完成させるとき、最も適切な組み合わせを答えなさい。",
        "question2": "銀河にはさまざまな形状のものがあるが、<br>［　①　］［　②　］［　③　］［　④　］局部銀河群という。<br><br>ア　天の川銀河を含む他の銀河を<br>イ　大きな銀河の集団を<br>ウ　アンドロメダ銀河をはじめ<br>エ　50個ほどまとめた",
        "answer": "3",
        "score": 1,
        "choice1": "①ア　②イ　③エ　④ウ",
        "choice2": "①イ　②ア　③ウ　④エ",
        "choice3": "①ウ　②ア　③エ　④イ",
        "choice4": "①エ　②ウ　③ア　④イ",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 105,
        "shikenId": 2,
        "parentNo": 20,
        "no": 1,
        "answerType": 1,
        "time": 50,
        "question1": "文中の①～③の空欄に入れる語として最も適切なものをア～ウの中から１つずつ選んだ組み合わせを答えなさい。",
        "question2": "染色体は生物の遺伝情報を含んだ構造体で、DNAとタンパク質から構成されます。細胞分裂時に［　①　］され、遺伝情報を新たな細胞に正確に伝えます。遺伝子は染色体上にあり、形質や特性を［　②　］します。染色体の異常は遺伝的な疾患の［　③　］となることもあります。<br>\t\t\t　　<br>ア　原因<br>イ　複製<br>ウ　決定",
        "answer": "4",
        "score": 1,
        "choice1": "①ア　②イ　③ウ",
        "choice2": "①ウ　②ア　③イ",
        "choice3": "①イ　②ア　③ウ",
        "choice4": "①イ　②ウ　③ア",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 106,
        "shikenId": 2,
        "parentNo": 21,
        "no": 1,
        "answerType": 1,
        "time": 80,
        "question1": "文中の①～③の空欄に入れる語として最も適切なものをア～ウの中から１つずつ選んだ組み合わせを答えなさい。",
        "question2": "政党は選挙を通じて政権獲得を目指す団体であり、党員や支持者の声を［　①　］しながら、政策の提案や実現に取り組むものである。政党政治は民主主義の［　②　］として重要であり、政治的な選択肢を提供し、政策の多様性や対話を［　③　］する。<br><br>ア　基盤<br>イ　促進<br>ウ　反映",
        "answer": "2",
        "score": 1,
        "choice1": "①ア　②イ　③ウ",
        "choice2": "①ウ　②ア　③イ",
        "choice3": "①イ　②ア　③ウ",
        "choice4": "①ア　②ウ　③イ",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 107,
        "shikenId": 2,
        "parentNo": 22,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "以下の文章を読んで問いに答えなさい。<br><br>　日本でポピュラーなビールはラガーといい、その発酵方法は酵母がタンクの底に沈積することから下面発酵と呼ばれる。麦汁を低温で長期間発酵させることにより、クリーンでスムーズな味わいが生まれるラガービールは、冷蔵技術の普及に伴って世界的に普及したものである。【 ａ 】日本ではかつて「ビールとは冷蔵庫などで冷やして飲み、喉越しを楽しむ苦みのある酒」というイメージが流布していたが、ピルスナーをはじめとするラガービールは、ビールのごく一部に過ぎない。【 ｂ 】<br>　［　　　］においてラガーと対照的なビールとしてエールがある。上面発酵で醸造されるエールには多様なスタイルがあり、個性的な風味のエールが数多く造られている。【 ｃ 】<br>　日本では酒税法においてビールの定義が定められており、世界のビールの中には日本に輸入された際にこの定義に外れるという理由で「発泡酒」とラベリングされるものがある。日本の「ビール」以外にも世界には多様なビールが存在しているのである。【 ｄ 】",
        "question2": "次の１文を挿入するのに最も適切な場所は、文中の【 ａ 】～【 ｄ 】のうちどこか。<br><br>さらに、ベルギーのパヨッテンラントという地域では、自然発酵で造られ酸味のあるフレーバーが特徴のランビックというビールも存在する。",
        "answer": "3",
        "score": 1,
        "choice1": "【 ａ 】",
        "choice2": "【 ｂ 】",
        "choice3": "【 ｃ 】",
        "choice4": "【 ｄ 】",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 108,
        "shikenId": 2,
        "parentNo": 22,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文章を読んで問いに答えなさい。<br><br>　日本でポピュラーなビールはラガーといい、その発酵方法は酵母がタンクの底に沈積することから下面発酵と呼ばれる。麦汁を低温で長期間発酵させることにより、クリーンでスムーズな味わいが生まれるラガービールは、冷蔵技術の普及に伴って世界的に普及したものである。【 ａ 】日本ではかつて「ビールとは冷蔵庫などで冷やして飲み、喉越しを楽しむ苦みのある酒」というイメージが流布していたが、ピルスナーをはじめとするラガービールは、ビールのごく一部に過ぎない。【 ｂ 】<br>　［　　　］においてラガーと対照的なビールとしてエールがある。上面発酵で醸造されるエールには多様なスタイルがあり、個性的な風味のエールが数多く造られている。【 ｃ 】<br>　日本では酒税法においてビールの定義が定められており、世界のビールの中には日本に輸入された際にこの定義に外れるという理由で「発泡酒」とラベリングされるものがある。日本の「ビール」以外にも世界には多様なビールが存在しているのである。【 ｄ 】",
        "question2": "文中で述べられていることから判断して、次のア、イの正誤を答えなさい。<br><br>ア　ラガービールは下面発酵により醸造され、冷えたラガービールを楽しむために冷蔵技術が発展した。<br>イ　日本で「発泡酒」とラベリングされるものの中には、世界ではビールとして飲まれるものもある。",
        "answer": "3",
        "score": 1,
        "choice1": "アもイも正しい",
        "choice2": "アは正しいがイは誤り",
        "choice3": "アは誤りだがイは正しい",
        "choice4": "アもイも誤り",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 109,
        "shikenId": 2,
        "parentNo": 22,
        "no": 3,
        "answerType": 3,
        "time": 0,
        "question1": "以下の文章を読んで問いに答えなさい。<br><br>　日本でポピュラーなビールはラガーといい、その発酵方法は酵母がタンクの底に沈積することから下面発酵と呼ばれる。麦汁を低温で長期間発酵させることにより、クリーンでスムーズな味わいが生まれるラガービールは、冷蔵技術の普及に伴って世界的に普及したものである。【 ａ 】日本ではかつて「ビールとは冷蔵庫などで冷やして飲み、喉越しを楽しむ苦みのある酒」というイメージが流布していたが、ピルスナーをはじめとするラガービールは、ビールのごく一部に過ぎない。【 ｂ 】<br>　［　　　］においてラガーと対照的なビールとしてエールがある。上面発酵で醸造されるエールには多様なスタイルがあり、個性的な風味のエールが数多く造られている。【 ｃ 】<br>　日本では酒税法においてビールの定義が定められており、世界のビールの中には日本に輸入された際にこの定義に外れるという理由で「発泡酒」とラベリングされるものがある。日本の「ビール」以外にも世界には多様なビールが存在しているのである。【 ｄ 】",
        "question2": "文中の空欄に入る語句を、文中から５文字以内で抜き出しなさい。",
        "answer": "発酵方法",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 110,
        "shikenId": 2,
        "parentNo": 23,
        "no": 1,
        "answerType": 1,
        "time": 180,
        "question1": "以下の文章を読んで問いに答えなさい。<br><br>　「デジタル」と「アナログ」はどう違うのか。これは、情報の表現や処理方法に関する基本的な考え方の違いに由来します。【 ａ 】デジタルは情報を離散的な値で表現する方式であり、アナログは情報を連続的な値で表現する方式です。【 ｂ 】<br>　デジタル信号は一連の離散した値（例えば、０と１）で構成されていますが、この性質によって、情報の取り扱いや伝送がより［　　　］に制御できます。【 ｃ 】また、デジタル情報はノイズの影響を受けにくく、信号の品質を保つことができます。【 ｄ 】<br>　一方、アナログ信号においては物理的な量（例えば、電圧や音声の振幅）が連続的に変化します。アナログ信号は自然界の多くの現象に近い形で情報を表現できますが、ノイズや外部の影響を受けやすく精密さに欠けます。",
        "question2": "次の１文を挿入するのに最も適切な場所は、文中の【 ａ 】～【 ｄ 】のうちどこか。<br><br>もっとわかりやすくいえば、計量や表示をとびとびの数量で行うのがデジタル、とびとびでない数量で行うのがアナログです。",
        "answer": "2",
        "score": 1,
        "choice1": "【 ａ 】",
        "choice2": "【 ｂ 】",
        "choice3": "【 ｃ 】",
        "choice4": "【 ｄ 】",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 111,
        "shikenId": 2,
        "parentNo": 23,
        "no": 2,
        "answerType": 1,
        "time": 0,
        "question1": "以下の文章を読んで問いに答えなさい。<br><br>　「デジタル」と「アナログ」はどう違うのか。これは、情報の表現や処理方法に関する基本的な考え方の違いに由来します。【 ａ 】デジタルは情報を離散的な値で表現する方式であり、アナログは情報を連続的な値で表現する方式です。【 ｂ 】<br>　デジタル信号は一連の離散した値（例えば、０と１）で構成されていますが、この性質によって、情報の取り扱いや伝送がより［　　　］に制御できます。【 ｃ 】また、デジタル情報はノイズの影響を受けにくく、信号の品質を保つことができます。【 ｄ 】<br>　一方、アナログ信号においては物理的な量（例えば、電圧や音声の振幅）が連続的に変化します。アナログ信号は自然界の多くの現象に近い形で情報を表現できますが、ノイズや外部の影響を受けやすく精密さに欠けます。",
        "question2": "文中で述べられていることから判断して、次のア、イの正誤を答えなさい。<br><br>ア　デジタルは情報を離散的な値で表現する手法であり、デジタル情報はノイズの影響を受けにくいという性質を持つ。<br>イ　アナログ信号は、０と１のように連続した値によって構成される。",
        "answer": "2",
        "score": 1,
        "choice1": "アもイも正しい",
        "choice2": "アは正しいがイは誤り",
        "choice3": "アは誤りだがイは正しい",
        "choice4": "アもイも誤り",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 112,
        "shikenId": 2,
        "parentNo": 23,
        "no": 3,
        "answerType": 3,
        "time": 0,
        "question1": "以下の文章を読んで問いに答えなさい。<br><br>　「デジタル」と「アナログ」はどう違うのか。これは、情報の表現や処理方法に関する基本的な考え方の違いに由来します。【 ａ 】デジタルは情報を離散的な値で表現する方式であり、アナログは情報を連続的な値で表現する方式です。【 ｂ 】<br>　デジタル信号は一連の離散した値（例えば、０と１）で構成されていますが、この性質によって、情報の取り扱いや伝送がより［　　　］に制御できます。【 ｃ 】また、デジタル情報はノイズの影響を受けにくく、信号の品質を保つことができます。【 ｄ 】<br>　一方、アナログ信号においては物理的な量（例えば、電圧や音声の振幅）が連続的に変化します。アナログ信号は自然界の多くの現象に近い形で情報を表現できますが、ノイズや外部の影響を受けやすく精密さに欠けます。",
        "question2": "文中の空欄に入る語句を、文中から３文字以内で抜き出しなさい。",
        "answer": "精密",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 113,
        "shikenId": 2,
        "parentNo": 24,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br><br>現在Ｐは53歳であり、５年前にＰの年齢はＱの年齢の４倍であった。現在、Ｑは［　　　］歳である。",
        "question2": "",
        "answer": "17",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 114,
        "shikenId": 2,
        "parentNo": 25,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br><br>Ｐ、Ｑ、Ｒの３人があるゲームで得点を競い合った。Ｐの得点はＲの得点の2/3、Ｑの得点はＲの得点の3/4であり、ＰとQの得点差が２点であるとき、Ｒの得点は［　　　］点である。",
        "question2": "",
        "answer": "24",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 115,
        "shikenId": 2,
        "parentNo": 26,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br><br>Ｐ、Ｑ、Ｒは正の整数であり、以下のことがわかっている。<br><br>ア　Ｐ×Ｑ×Ｒ＝90<br>イ　8＞Ｐ＞Ｑ＞Ｒ＞2<br><br>このとき、Ｐは［　　　］である。",
        "question2": "",
        "answer": "6",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 116,
        "shikenId": 2,
        "parentNo": 27,
        "no": 1,
        "answerType": 1,
        "time": 60,
        "question1": "以下について、ア、イの情報のうち、どれがあれば［問い］の答えが分かるかを考え、Ａ～Ｅの中から正しいものを１つ選び、答えなさい。<br><br><br>Ｐ、Ｑ、Ｒの３人が１回ずつサイコロを振ったところ、３人が出した目の合計は10だった。",
        "question2": "［問い］Ｑが出した目はいくつか。<br><br>ア　Ｐ、Ｑ、Ｒの３人が出した目はすべて異なっていた<br>イ　Ｐが出した目は、Ｒより４大きかった",
        "answer": "3",
        "score": 1,
        "choice1": "アだけで分かるが、イだけでは分からない",
        "choice2": "イだけで分かるが、アだけでは分からない",
        "choice3": "アとイの両方で分かるが、片方だけでは分からない",
        "choice4": "アだけでも、イだけでも分かる",
        "choice5": "アとイの両方があっても分からない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 117,
        "shikenId": 2,
        "parentNo": 28,
        "no": 1,
        "answerType": 1,
        "time": 60,
        "question1": "以下について、ア、イの情報のうち、どれがあれば［問い］の答えが分かるかを考え、Ａ～Ｅの中から正しいものを１つ選び、答えなさい。<br><br><br>Ｐ、Ｑの２人が100点満点の数学の実力テストを受けた。",
        "question2": "［問い］Ｐの得点は何点か。<br><br>ア　２人の得点差は28点であった<br>イ　２人の平均点は72点であった",
        "answer": "5",
        "score": 1,
        "choice1": "アだけで分かるが、イだけでは分からない",
        "choice2": "イだけで分かるが、アだけでは分からない",
        "choice3": "アとイの両方で分かるが、片方だけでは分からない",
        "choice4": "アだけでも、イだけでも分かる",
        "choice5": "アとイの両方があっても分からない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 118,
        "shikenId": 2,
        "parentNo": 29,
        "no": 1,
        "answerType": 1,
        "time": 60,
        "question1": "以下について、ア、イの情報のうち、どれがあれば［問い］の答えが分かるかを考え、Ａ～Ｅの中から正しいものを１つ選び、答えなさい。<br><br><br>Ｐ、Ｑ、Ｒ、Ｓは１から９までの整数のいずれかであり、Ｐ＞Ｑ＞Ｒ＞Ｓである。",
        "question2": "［問い］Ｑはいくつか。<br><br>ア　Ｓ＜2<br>イ　Ｐ＜5",
        "answer": "2",
        "score": 1,
        "choice1": "アだけで分かるが、イだけでは分からない",
        "choice2": "イだけで分かるが、アだけでは分からない",
        "choice3": "アとイの両方で分かるが、片方だけでは分からない",
        "choice4": "アだけでも、イだけでも分かる",
        "choice5": "アとイの両方があっても分からない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 119,
        "shikenId": 2,
        "parentNo": 30,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>Ｐ、Ｑの２人が、１周3.1kmの池の周りにある散歩コースを歩くことにした。同じスタート地点から、まずＰが時速3.6kmで歩き始め、その12分後にＱが時速4.8kmでＰと反対方向に歩き始めた。ＰとＱが散歩コース上で最初に出会うのは、Ｐが歩き始めてから［　　　］分後である（必要なときは、最後に小数第１位を四捨五入すること）。",
        "question2": "",
        "answer": "29",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 120,
        "shikenId": 2,
        "parentNo": 31,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>定価1800円のある商品は５個まとめて購入すると定価の10％割引となり、６個、７個、８個…とまとめて購入する個数が多くなるほど割引率が大きくなる。この商品を15個まとめて購入するときの割引価格は定価の［　　　］％引きであり、５個まとめて購入するときに比べて１個あたり108円安価である（必要なときは、最後に小数第１位を四捨五入すること）。",
        "question2": "",
        "answer": "16",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 121,
        "shikenId": 2,
        "parentNo": 32,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>ある商品を50個仕入れ、仕入値に対して25％の利益を見込んで2400円の定価とした。当初定価で販売していたものの、売れ行きが悪かったため途中で定価の15％引きで販売したところ完売した。定価で販売した個数が15％引きで販売した個数より14個少ないとき、結果として仕入値の総額に対して得られた利益は［　　　］％である（必要なときは、最後に小数第１位を四捨五入すること）。",
        "question2": "",
        "answer": "13",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 122,
        "shikenId": 2,
        "parentNo": 33,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>Ｐ、Ｑ、Ｒの３人は、４階建てのマンションのそれぞれ異なる階に住んでいる。３人の住んでいる階について、以下のことが分かっている。<br><br>ア　ＰはＲの２階上に住んでいる<br>イ　住んでいる階が３人のうち最も上なのはＰではない<br><br>このとき、Ｑは［　　　］階に住んでいる。",
        "question2": "",
        "answer": "4",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 123,
        "shikenId": 2,
        "parentNo": 34,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>110円、140円、180円の切手が何枚かあり、以下のことが分かっている。<br><br>ア　切手の金額の合計は1030円である<br>イ　140円切手の枚数が一番多い<br><br>このとき、切手は全部で［　　　］枚ある。",
        "question2": "",
        "answer": "7",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 124,
        "shikenId": 2,
        "parentNo": 35,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>ある人が４月の１か月間にしたアルバイトで得た収入は80000円だった。この人が４月から６月までの３か月間にしたアルバイトで得た収入について、以下のことが分かっている。<br><br>ア　４月の収入は、３か月間の収入の平均より2000円少なかった<br>イ　３か月で最も収入の少ない月は４月であり、４月と５月の収入の差は、４月と６月の収入の差の1/4だった<br><br>このとき、６月の収入は［　　　］円である。",
        "question2": "",
        "answer": "84800",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 125,
        "shikenId": 2,
        "parentNo": 36,
        "no": 1,
        "answerType": 1,
        "time": 60,
        "question1": "空欄にあてはまる数値をＡ～Ｅの中から１つ選びなさい。<br><br><br>赤玉が５個、白玉が４個入った箱から玉を同時に３つ取り出す。このとき、取り出された玉が赤玉２個、白玉１個である確率は［　　　］である。",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "20/243",
        "choice2": "80/729",
        "choice3": "100/729",
        "choice4": "5/42",
        "choice5": "10/21",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 126,
        "shikenId": 2,
        "parentNo": 37,
        "no": 1,
        "answerType": 1,
        "time": 60,
        "question1": "空欄にあてはまる数値をＡ～Ｅの中から１つ選びなさい。<br><br><br>１組のトランプの絵札12枚をよく切り、３枚を取り出してテーブルに並べたとき、ジャック、クイーン、キングが１枚ずつである確率は［　　　］である。",
        "question2": "",
        "answer": "2",
        "score": 1,
        "choice1": "8/165",
        "choice2": "16/55",
        "choice3": "58/165",
        "choice4": "24/55",
        "choice5": "98/165",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 127,
        "shikenId": 2,
        "parentNo": 38,
        "no": 1,
        "answerType": 1,
        "time": 60,
        "question1": "空欄にあてはまる数値をＡ～Ｅの中から１つ選びなさい。<br><br><br>表裏のあるコインを３回投げたとき、少なくとも１回は表が出る確率は［　　　］である。",
        "question2": "",
        "answer": "5",
        "score": 1,
        "choice1": "1/4",
        "choice2": "1/2",
        "choice3": "3/4",
        "choice4": "5/6",
        "choice5": "7/8",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 128,
        "shikenId": 2,
        "parentNo": 39,
        "no": 1,
        "answerType": 4,
        "time": 180,
        "question1": "表は、ある年におけるＰ、Ｑ、Ｒ、Ｓの４地域の耕作面積を田と畑に分けてまとめたものである。以下の３問に答えなさい。<br><br><br><img src='/img/1_2_49_1.png' class='q-img'/>",
        "question2": "４地域の耕作面積合計に占めるＱ地域の耕作面積の割合は［　　　］％である（必要なときは、最後に小数第１位を四捨五入すること）。",
        "answer": "34",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 129,
        "shikenId": 2,
        "parentNo": 39,
        "no": 2,
        "answerType": 4,
        "time": 0,
        "question1": "表は、ある年におけるＰ、Ｑ、Ｒ、Ｓの４地域の耕作面積を田と畑に分けてまとめたものである。以下の３問に答えなさい。<br><br><br><img src='/img/1_2_49_1.png' class='q-img'/>",
        "question2": "４地域の耕作面積合計に占める田の面積の割合は［　　　］％である（必要なときは、最後に小数第１位を四捨五入すること）。",
        "answer": "64",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 130,
        "shikenId": 2,
        "parentNo": 39,
        "no": 3,
        "answerType": 1,
        "time": 0,
        "question1": "表は、ある年におけるＰ、Ｑ、Ｒ、Ｓの４地域の耕作面積を田と畑に分けてまとめたものである。以下の３問に答えなさい。<br><br><br><img src='/img/1_2_49_1.png' class='q-img'/>",
        "question2": "地域ごとの耕作面積合計に占める畑の面積の割合を表したグラフは、次のＡ～Ｄのうちどれに最も近いか。",
        "answer": "1",
        "score": 1,
        "choice1": "<img src='/img/1_2_51_1.png' class='c-img'/>",
        "choice2": "<img src='/img/1_2_51_2.png' class='c-img'/>",
        "choice3": "<img src='/img/1_2_51_3.png' class='c-img'/>",
        "choice4": "<img src='/img/1_2_51_4.png' class='c-img'/>",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 131,
        "shikenId": 2,
        "parentNo": 40,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>大学生100人に英語とフランス語の履修状況について尋ねた。英語のみを履修している学生数とフランス語のみを履修している学生数の比率が7：5で、英語もフランス語も履修していない学生数は英語とフランス語の両方を履修している学生数より20人多かった。英語とフランス語の少なくとも一方を履修している学生数が76名であるとき、英語のみを履修している学生数は［　　　］人である。",
        "question2": "",
        "answer": "42",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 132,
        "shikenId": 2,
        "parentNo": 41,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>ある高校に通う生徒800人に部活動と委員会活動への参加状況を調査したところ、部活動に参加している生徒は70％、委員会活動に参加している生徒は32％であり、部活動と委員会活動の両方に参加している生徒は部活動にも委員会活動にも参加していない生徒の２倍だった。このとき、部活動と委員会活動のどちらか一方のみに参加している生徒は［　　　］人である。",
        "question2": "",
        "answer": "752",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 133,
        "shikenId": 2,
        "parentNo": 42,
        "no": 1,
        "answerType": 4,
        "time": 60,
        "question1": "空欄にあてはまる数値を求めなさい。<br><br><br>ある会社の従業員200人に路線Ｐと路線Ｑの通勤での利用について尋ねた。路線Ｐと路線Ｑのいずれも利用していない人数が24人であり、これが路線Ｑを利用している人数の30％であるとき、路線Ｐのみを利用している人数は［　　　］人である。",
        "question2": "",
        "answer": "96",
        "score": 1,
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    }
]