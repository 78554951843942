
import { store, storeKey } from "@/services/Store";
import { defineComponent, inject } from "vue";
export default defineComponent({
  name: "TimerGage",

  emits: ["overTime"],
  props: {
    time: {
      type: Number,
      required: true
    },
  },
  setup(props, context) {
    const overTimeParent = () => {
      context.emit("overTime");
    };
    return {
      overTimeParent,
    };
  },
  data() {
    return {
      isEnd: false as boolean,
      interval: null as any,
      goalDate: new Date() as any,
      timerStr: "" as string,
      rest: 0 as number,
      restFloat: 0 as number,
      per: 1.0 as number
    };
  },
  created() {
    const vm = this;
  },
  mounted() {
    this.rest = this.time;
    this.start();
  },
  methods: {
    start() {
      if (!this.time || this.time == 0) return;
      this.goalDate = new Date(+new Date() + this.time * 1000);
      this.isEnd = false
      const vm = this;
      this.setTimer();
      this.interval = setInterval(function () {
        vm.countdown(vm.goalDate);
        
        if (vm.restFloat <= 0) {
          clearInterval(vm.interval);
          vm.per = 0
          vm.overTime(vm.restFloat);
        }
      }, 100);
    },

    stop() {
      clearInterval(this.interval);
    },

    setEnd() {
      this.isEnd = true
    },

    countdown(due: Date) {
      var now = new Date();
      var rest = due.getTime() - now.getTime();
      this.restFloat = rest
      this.per = rest / (this.time * 1000)
      this.rest = Math.floor(rest / 1000);
      
    },

    setTimer() {
      var counter = this.countdown(this.goalDate);

    },

    overTime(rest: number) {
      if (rest <= 0) {
        const vm = this
        setTimeout(function() {
          if (!vm.isEnd) {
            vm.isEnd = true
            vm.overTimeParent();
          }
          
}, 1000);
       
      }
    },
  },
});
