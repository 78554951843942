
export default [
  {
      "id": 1,
      "name": "テストセンター受検者向け",
      "no": 1
  },
  {
    "id": 2,
    "name": "Webテスト受検者向け",
    "no": 2
}
]